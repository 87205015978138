<template>
  <div id="updated-roster">
    <b-card>
      <FullCalendar ref="FullCalendar" :options="calendarOptions">
        <template v-slot:eventContent="arg">
          <b>{{ arg.timeText }}</b>
          <i>{{ arg.event.title }}</i>
        </template>
      </FullCalendar>
    </b-card>
  </div>
</template>
<script>
import store from "@/store";
import { getLogedInUser, getToken } from "@/utils/auth";
import { VUE_APP_BASE_URL } from "@/utils/var";
import { default as dayGridMonth, default as dayGridPlugin } from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import FullCalendar from "@fullcalendar/vue";
import {
BButton,
BCard,
BFormGroup,
BFormInput,
BFormTextarea,
BFormTimepicker,
} from "bootstrap-vue";
import vSelect from "vue-select";

export default {
  components: {
    BCard,
    BFormGroup,
    BFormInput,
    BFormTimepicker,
    BButton,
    BFormTextarea,
    FullCalendar, // make the <FullCalendar> tag available
    vSelect,
  },
  props: {
    filterData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      clicked: "edit",
      duties: [],
      customers: [],
      companies: [],
      shifts: [],
      employeeAvailable: {},
      employeeExistingRosters: [],
      employeePassportValid: "",
      passportValidDays: 0,
      drivingValidDays: 0,
      visaValidDays: 0,
      certificateValidDays: 0,
      ableToCreateRoster: true,
      logedinUser: JSON.parse(getLogedInUser()),
      query: {
        role: "admin",
        company: null,
        customer: null,
        user_id: null,
        customer_id: null,
      },
      roster: {
        company: null,
        duty: null,
        shift: null,
        customer: null,
        customer_id: "",
        user_id: "",
        duty_id: "",
        start_time: "",
        end_time: "",
        date: "",
        employee_id: "",
        shift_id: "",
        note: "",
        status: null,
      },
      publishData: {
        status: 1,
        id: [],
      },
      statuses: [
        { value: 0, text: "Unpublish" },
        { value: 1, text: "Publish" },
        { value: 2, text: "Accept" },
        { value: 3, text: "Decline" },
      ],
      nameState: null,
      calendarOptions: {
        plugins: [
          resourceTimelinePlugin,
          interactionPlugin,
          dayGridPlugin,
          dayGridMonth,
        ],
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right:
            "resourceTimelineTenDay resourceTimelineFourtenDay resourceTimelineTwentyOneDay resourceTimelineTwentyEightDay",
        },
        initialView: "resourceTimelineTenDay",
        aspectRatio: 1.5,
        resourceAreaColumns: [
          {
            field: "title",
            headerContent: "Employee List",
          },
        ],
        eventTimeFormat: {
          date: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        },
        views: {
          resourceTimelineTenDay: {
            type: "resourceTimeline",
            duration: { week: 1 },
            dateIncrement: { weeks: 1 },
            buttonText: "7 days",
            slotDuration: { days: 1 },
          },
          resourceTimelineFourtenDay: {
            type: "resourceTimeline",
            duration: { week: 2 },
            dateIncrement: { weeks: 2 },
            buttonText: "2 Week",
            slotDuration: { days: 1 },
          },
          resourceTimelineTwentyOneDay: {
            type: "resourceTimeline",
            duration: { week: 3 },
            dateIncrement: { weeks: 3 },
            buttonText: "3 Week",
            slotDuration: { days: 1 },
          },
          resourceTimelineTwentyEightDay: {
            type: "resourceTimeline",
            duration: { week: 4 },
            dateIncrement: { weeks: 4 },
            buttonText: "4 Week",
            slotDuration: { days: 1 },
          },
        },
        slotLabelFormat: [
          { weekday: "short", year: "numeric", month: "short", day: "numeric" },
        ],
        slotLabelInterval: { days: 1 },
        
        events: {
          url: `${VUE_APP_BASE_URL}/roster/event`,
          method: "GET",
          extraParams: {
            type: "event",
            Authorization: `Bearer ${getToken()}`,
            status: 1,
            customer_id: this.$route.params.id,
 
          },
        },
        resources: {
          url: `${VUE_APP_BASE_URL}/roster/resource`,
          method: "GET",
          extraParams: {
            Authorization: `Bearer ${getToken()}`,
            customer_id: this.$route.params.id,
          },
        },
        eventDidMount: function (info) {
          info.el.style.margin = "5px";
          if (info.event?.title == "Add Roster") {
            if (info.event?.extendedProps?.count == 0) {
              info.el.style.backgroundColor = "#f9f9f9";
              info.el.style.padding = "55px";
              let title = document.createElement("span");
              let icon = document.createElement("i");
              icon.style.textAlign = "center";
              icon.style.fontSize = "14px";
              icon.style.color = "#A8ADAF";
              icon.innerHTML = `Unallocated`;
              title.appendChild(icon);
              info.el.prepend(title);
              info.el.querySelector(".fc-event-main").style.display =
                "none !important";
            }
          } else {
            let main = info.el.querySelector(".fc-event-main");
            main.style.width = "100%";
            main.style.textAlign = "end";
            info.el.style.padding = "20px 2px";
            let div = document.createElement("div");
            div.style.marginTop = "5px";
            div.style.display = "flex";
            div.style.justifyContent = "space-between";
            div.style.flexDirection = "column";
            div.style.alignItems = "center";
            div.style.width = "100%";
            let div1 = document.createElement("div");

            div1.classList.add("duty");
            div1.innerHTML = info.event.extendedProps.duty
              ? info.event.extendedProps.duty.name
              : "N/A";
            div1.setAttribute("title", "Assigned Duty");

            let client = document.createElement("div");
            client.classList.add("duty");
            client.innerHTML = info.event.extendedProps.client_company
              ? info.event.extendedProps.client_company
              : "N/A";
            client.setAttribute("title", "Client Company");
           
            let location = document.createElement("div");
            location.classList.add("duty");
            // add location name and sub location name if sub location is available else add location name only to the event
            location.innerHTML = info.event.extendedProps.sub_location
              ? `${info.event.extendedProps.sub_location.name}, ${info.event.extendedProps.location.name}`
              : info.event.extendedProps.location ? info.event.extendedProps.location.name : '';

            location.setAttribute("title", "Location");

            if (
              info.event.extendedProps.status === 1 &&
              info.event.extendedProps.e_status === 2
            ) {
              let accept = document.createElement("div");
              accept.innerHTML = `<button type="button" class="btn btn-sm btn-success rounded text-light btn-accept custom">Accepted</button> `;

              div.append(accept);
            }

            if (
              info.event.extendedProps.status === 1 &&
              info.event.extendedProps.e_status === 3
            ) {
              let accept = document.createElement("div");
              accept.innerHTML = `<button type="button" class="btn btn-sm btn-danger rounded text-light btn-accept custom">Declined</button> `;

              div.append(accept);
            }

            info.el.append(client, div1, location, div);
          }
        },
        schedulerLicenseKey: "GPL-My-Project-Is-Open-Source",
        resourceAreaWidth: "15%",
        resourceLabelDidMount: function (info) {
          let find = info.el.querySelector(".fc-datagrid-cell-cushion");
          //find.classList.add('labelLane');
          let div2 = document.createElement("span");
          div2.classList.add("fc-datagrid-cell-main");
          div2.classList.add("id");
          let div = document.createElement("span");
          let div3 = document.createElement("span");
          div3.classList.add("fc-datagrid-cell-main");
          div3.classList.add("name");
          let img = document.createElement("img");
          img.style.height = "50px";
          img.style.width = "50px";
          img.style.borderRadius = "50%";
          img.style.border = "1px solid #000";
          img.style.objectFit = "cover";
          img.style.objectPosition = "center";
          div2.innerHTML = "IDN" + info.resource.extendedProps.idn;
          div3.innerHTML = info.resource.extendedProps.position
            ? info.resource.extendedProps.position
            : "N/A";
          img.src = info.resource.extendedProps.img.includes(
            "https://ui-avatars.com"
          )
            ? info.resource.extendedProps.img
            : store.state.appConfig.imageBaseUrl +
              info.resource.extendedProps.img;
          div.innerHTML = find.prepend(img, div2);
          div.innerHTML = find.append(div3);
          // return { domNodes: [div] };
        },
      },
    };
  },

};
</script>
<style scoped>
.fc-license-message {
  display: none;
}
.timeline-event {
  background-color: #f00;
  color: #000;
  border: 1px solid #f00;
  border-radius: 3px;
  padding: 2px 5px;
  font-size: 11px;
  font-weight: bold;
  cursor: pointer;
}
.fc-datagrid-cell-frame:last-child {
  border: 1px solid #bebebe;
}
.fc-timeline-lane-frame {
  border: 1px solid #bebebe;
}

.labelLane {
  align-items: center !important;
  border-radius: 0;
  display: flex !important;
  /*font-size: var(--fc-small-font-size);*/
  margin-bottom: 1px !important;
  padding: 2px 1px !important;
  position: relative !important;
  font-weight: bold;
}

.timeline-event {
  display: flex;
  align-items: center;
}
.fc-timeline-events .fc-event-main i,
.fc-timeline-events .fc-event-main {
  color: #a8adaf !important;
  text-align: center;
  display: none;
}

.fc-timeline-events {
  height: 100% !important;
}

.id {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #79869f;
  padding: 5px;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
