<template>
  <div id="customer-profile" class="rounded-lg bg-white p-2 shadow-lg">
    <div class="d-flex justify-content-between">
      <b-link to="/customers" class="btn btn-danger"> Back </b-link>
      <router-link
        v-if="$can('update', 'Client')"
        :to="`/customer/edit/${$route.params.id}`"
      >
        <button class="px-2 btn btn-sm edit-btn">
          <span class="btn-text">Edit</span>
          <img src="@/assets/images/icons/edit-3.png" alt="" />
        </button>
      </router-link>
    </div>

    <form-component :customer="customer" mode="show" />

    <div
      class="row card py-1"
      v-if="companies.length > 0 && logedInUser.roles[0].name === 'superadmin'"
    >
      <div class="col-12">
        <p class="head-text">Companies</p>
        <hr />
        <b-table
          class="data-text-custom"
          striped
          hover
          :items="companies"
          :fields="fields"
        >
          <template #cell(sl)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(action)="data">
            <b-link
              v-if="$can('view', 'Company')"
              :to="`/company/view/${data.item.id}`"
              class="btn btn-info btn-sm"
            >
              View
            </b-link>
          </template>
        </b-table>
      </div>
    </div>
    <div
      class="row card py-1"
      v-if="logedInUser.roles[0].name === 'superadmin' ||
          logedInUser.roles[0].name === 'admin'"
    >
      <div class="col-12">
        <div class="d-flex justify-content-between align-items-center">
          <p class="head-text">Employees</p>
          <div class="d-flex flex-row align-items-center">
            <label for="" class="label-font">Status :</label>
            <button @click="changeStatus(0, 'employee')" class="btn">
              <span class="active-success"></span>
              <span :class="empQuery.status === 0 ? 'has-border' : ''"
                >Active</span
              >
            </button>
            <button @click="changeStatus(1, 'employee')" class="btn p-0">
              <span class="active-warning"></span>
              <span :class="empQuery.status === 1 ? 'has-border' : ''"
                >Inactive</span
              >
            </button>
            <button @click="changeStatus(2, 'employee')" class="btn">
              <span class="active-danger"></span>
              <span :class="empQuery.status === 2 ? 'has-border' : ''"
                >Company/Client Free</span
              >
            </button>
          </div>
        </div>
        <hr />
        <b-table
          class="data-text-custom"
          striped
          hover
          :items="employees"
          :fields="employeeFields"
          empty-text="No employee found"
          show-empty
        >
        <template #empty="scope">
          <h4 class="text-center py-1">{{ scope.emptyText }}</h4>
        </template>
          <template #cell(sl)="data">
            <div
              :class="`p-1 ${
                data.item.status === 0 && data.item.user_status === 'active'
                  ? 'active-success'
                  : data.item.status === 0 && data.item.user_status === 'free' ? 'active-danger' : 'active-warning'
              }`"
            >
              {{ data.index + fromEmployee }}
            </div>
          </template>
          <template #cell(idn)="data">
            {{ data.item.serial_number }}
          </template>
          <template #cell(address)="data">
            {{ data.item.employee.address }}
          </template>
          <template #cell(action)="data">
            <b-link
              v-if="$can('view', 'Employee')"
              :to="`/employee-preview/${data.item.id}`"
              class="btn btn-info btn-sm"
            >
              View
            </b-link>
          </template>
        </b-table>
        <b-pagination
          class="mt-3 p-1 d-flex justify-content-end bg-white"
          v-if="totalEmployee > empQuery.per_page"
          v-model="empQuery.page"
          :total-rows="totalEmployee"
          :per-page="empQuery.per_page"
          aria-controls="my-table"
          @change="getEmployees($event)"
          pills
          size="lg"
          page-class="info"
        ></b-pagination>
      </div>
    </div>
    <div
      class="row card py-1"
      v-if="logedInUser.roles[0].name === 'superadmin' ||
          logedInUser.roles[0].name === 'admin'"
    >
      <div v-if="$can('list', 'Timesheet')" class="col-12">
        <div class="row d-flex align-items-center">
          <div class="col-md-6">
            <p class="head-text">Timesheets</p>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-8">
                <label for="" class="label-font">Filter By Date</label>
                <b-form-group class="my-date-range">
                  <flat-pickr
                    v-model="rangeDate"
                    class="form-control bg-light border-dark-1"
                    :config="{ mode: 'range', altInput: true }"
                    placeholder="Select Date Range & Filter"
                    v-debounce:1000="getRosterWorkList"
                  />
                  <button
                    v-if="rangeDate"
                    class="btn btn-sm btn-danger text-end d-block ml-1"
                    type="button"
                    title="Clear"
                    data-clear
                    @click="clearDate"
                  >
                    <span>X</span>
                  </button>
                </b-form-group>
              </div>
              <div class="col-4">
                <b-form-group>
                  <h5>Per Page</h5>
                  <v-select
                    v-model="query.per_page"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="per_pages"
                    placeholder="Filter By Per Page"
                    :clearable="false"
                    @input="getRosterWorkList({ page: query.page, per_page: query.per_page})"
                  />
                </b-form-group>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <time-sheet-table-component
          :roster-works="employeeWorkHistory"
          :fields="timesheetFields"
          :total="total"
          :form="from"
        />
        <b-pagination
          class="mt-3 p-1 d-flex justify-content-end bg-white"
          v-if="total > query.per_page"
          v-model="query.page"
          :total-rows="total"
          :per-page="query.per_page"
          aria-controls="my-table"
          @change="getRosterWorkList($event)"
          pills
          size="lg"
          page-class="info"
        ></b-pagination>
      </div>
    </div>
    <div
      class="row card py-1"
      v-if="
        employees.length > 0 &&
        (logedInUser.roles[0].name === 'superadmin' ||
          logedInUser.roles[0].name === 'admin')
      "
    >
      <div id="roster" v-if="$can('view published roster', 'Roster')" class="col-12">
        <p class="head-text">Rosters</p>
        <hr />
        <roster-component  />
      </div>
    </div>
  </div>
</template>

<script>
import TimeSheetTableComponent from "@/components/timesheet/TimeSheetTableComponent.vue";
import { getLogedInUser } from "@/utils/auth";
import { BFormGroup, BLink, BPagination, BTable } from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import FormComponent from "./components/FormComponent.vue";
import RosterComponent from "./components/RosterComponent.vue";
export default {
  components: {
    FormComponent,
    BLink,
    BTable,
    BPagination,
    BFormGroup,
    RosterComponent,
    TimeSheetTableComponent,
    flatPickr,
    vSelect,
  },
  data() {
    return {
      customer: {},
      employeeWorkHistory: [],
      customers: [],
      companies: [],
      employees: [],
      total: 0,
      totalEmployee: 0,
      from: 0,
      fromEmployee: 0,
      rangeDate: null,
      logedInUser: JSON.parse(getLogedInUser()),
      query: {
        page: 1,
        per_page: 20,
        customer_id: "",
      },
      empQuery: {
        page: 1,
        per_page: 20,
        customer_id: "",
      },
      per_pages: [20, 50, 100, 200, 500],
      fields: ["sl", "id", "name", "phone", "email", "action"],
      employeeFields: [
        "sl",
        "idn",
        "name",
        "phone",
        "email",
        "address",
        "action",
      ],
      timesheetFields: [
        "SL",
        "date",
        JSON.parse(getLogedInUser()).roles[0].name === "admin"
          ? ""
          : JSON.parse(getLogedInUser()).roles[0].name !== "employee"
          ? "company"
          : "assignedBy",

        JSON.parse(getLogedInUser()).roles[0].name === "customer"
          ? ""
          : JSON.parse(getLogedInUser()).roles[0].name !== "employee"
          ? "client"
          : "assignedTo",
        JSON.parse(getLogedInUser()).roles[0].name !== "employee"
          ? "employee"
          : "",
        "in",
        "out",
        "shift_hours",
        // "m",
        // "e",
        // "n",
        "ot_1",
        "ot_2",
        "total_hours",
        "status",
      ],
    };
  },
  created() {
    this.getCustomer();
    this.getRosterWorkList();
    this.getEmployees();
  },
  methods: {
    changeStatus(status) {
      this.empQuery.status = status;
      this.empQuery.page = 1;
      this.getEmployees();
    },
    async getCustomer() {
      await this.$store
        .dispatch("customer/getCustomer", this.$route.params.id)
        .then((response) => {
          this.customer = response.data;
          this.companies = this.customer?.customer_to_company;
          this.customers.push(response.data);
          if (this.customer.customer.contacts.length < 1) {
            this.customer.customer.contacts.push({
              first_name: "",
              last_name: "",
              email: "",
              phone: "",
              designation_id: "",
            });
          }
          if (this.customer.customer.financial.length < 1) {
            this.customer.customer.financial.push({
              first_name: "",
              last_name: "",
              email: "",
              phone: "",
              designation_id: "",
              type: "financial",
            });
          }
        });
    },
    getEmployees(e) {
      if (e && typeof e === "number") {
        this.empQuery.page = e;
      } else {
        this.empQuery.page = 1;
      }
      if (this.logedInUser.roles[0].name === "customer") {
        this.empQuery.user_id = this.logedInUser.id;
      } else {
        this.empQuery.customer_id = this.$route.params.id;
      }

      if (this.empQuery.status === undefined) {
        this.empQuery.status = 0;
      }
      this.$store
        .dispatch("employee/getEmployees", this.empQuery)
        .then((res) => {
          this.employees = res.data.data;
          this.totalEmployee = res.data.total;
          this.fromEmployee = res.data.from;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    clearDate() {
      this.rangeDate = null;
      this.query.date = "";
      this.query.end = "";
      this.getRosterWorkList();
    },
    getRosterWorkList(e) {
      if(e && typeof e === 'object'){
        this.query.status = null;
        this.query.page = e.page
        this.query.per_page = e.per_page;
      }
      if (e && typeof e === "number") {
        this.query.status = null;
        this.query.page = e;
      }
      if (this.rangeDate && this.rangeDate.length > 20) {
        // split the string in arrar
        let arr = this.rangeDate.split(" to ");
        this.query.date = arr[0];
        this.query.end = arr[1];
      }

      this.query.customer_id = this.$route.params.id;
      if (this.logedInUser.roles[0].name === "admin") {
        let roles = this.logedInUser.roles.map((role) => {
          return role.name;
        });
        if (roles.includes("supervisor")) {
          this.query.user_id = this.logedInUser.supervisor.company_id;
        } else {
          this.query.user_id = this.logedInUser.id;
        }
      }
      this.query.status = "";
      this.$store
        .dispatch("roster/getRosterbyId", this.query)
        .then((response) => {
          this.employeeWorkHistory = response.data.data;
          this.total = response.data.total;
          this.from = response.data.from;
        });
    },
  },
};
</script>

<style scoped>
.edit-btn {
  background: linear-gradient(315deg, #01185e 0%, #264296 44.92%, #7190ef 100%);
  border-color: transparent !important;
  background: linear-gradient(315deg, #01185e 0%, #264296 44.92%, #7190ef 100%);
}
.active-success {
  border-left: 4px solid #50c878;
  margin-right: 5px;
}

.active-danger {
  border-left: 4px solid #ff4f5d;
  margin-right: 5px;
}
.active-warning {
  border-left: 4px solid #ff9f43;
  margin-right: 5px;
}
.btn-text {
  font-weight: 700;
  font-size: 13px;
  line-height: 21px;
  padding-right: 10px;
  color: #ffffff;
}

.data-text-custom {
  font-weight: 700;
  font-size: 13px;
  line-height: 24px;
}
.head-text {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  color: #515b60;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
