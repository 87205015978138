<template>
  <div>
    <b-table
      responsive
      striped
      hover
      :items="rosterWorks"
      :fields="fields"
      empty-filtered-text="No Timesheet Found"
    >
      <template #cell(SL)="row">
        <span> {{ form + row.index }}</span>
      </template>
      <template #cell(date)="row">
        <span> {{ formateDate(row.item.date) }}</span>
      </template>
      <template #cell(employee)="row">
        <span>{{ row.item.employee ? row.item.employee.name : "N/A" }}</span>
      </template>
      <template
        v-if="logedInUser.roles[0].name !== 'employee'"
        #cell(company)="row"
      >
        <span>{{ row.item.roster.company.name }}</span>
      </template>
      <template v-else #cell(assignedBy)="row">
        <span>{{ row.item.roster.company.name }}</span>
      </template>
      <template
        v-if="logedInUser.roles[0].name !== 'employee'"
        #cell(client)="row"
      >
        <span>{{
          row.item.roster && row.item.roster.customer
            ? row.item.roster.customer.name
            : "N/A"
        }}</span>
      </template>
      <template v-else #cell(assignedTo)="row">
        <span>{{
          row.item.roster && row.item.roster.customer
            ? row.item.roster.customer.name
            : "N/A"
        }}</span>
      </template>
      <template #cell(in)="row">
        <span>{{ formateTime(row.item.in_time) }}</span>
      </template>
      <template #cell(out)="row">
        <span>{{ row.item.out_time === row.item.in_time ? '---' : formateTime(row.item.out_time) }}</span>
      </template>

      <template #cell(shift_hours)="row">
        <span
          v-if="
            logedInUser.roles[0].name === 'admin' ||
            logedInUser.roles[0].name === 'superadmin'
          "
          >{{ row.item.hours.adminOrdinary }}
        </span>
        <span v-else>{{ row.item.hours.ordinary }} </span>
      </template>
      <template #cell(m)="row">
        <div
          v-if="
            logedInUser.roles[0].name === 'admin' ||
            logedInUser.roles[0].name === 'superadmin'
          "
        >
          <span v-if="row.item.shift_type.shift.toLowerCase() == 'morning'">{{
            makeFloat(row.item.shift_type.deduct_hours)
          }}</span>
          <span
            v-else-if="
              row.item.shift_type.next.next_shift.toLowerCase() == 'morning'
            "
            >{{ makeFloat(row.item.shift_type.deduct_next) }}</span
          >
          <span v-else>0.00</span>
        </div>
        <div v-else>
          <span v-if="row.item.shift_type.shift.toLowerCase() == 'morning'">{{
            makeFloat(row.item.shift_type.hours)
          }}</span>
          <span
            v-else-if="
              row.item.shift_type.next.next_shift.toLowerCase() == 'morning'
            "
            >{{ makeFloat(row.item.shift_type.next.hours) }}</span
          >
          <span v-else>0.00</span>
        </div>
      </template>
      <template #cell(e)="row">
        <div
          v-if="
            logedInUser.roles[0].name === 'admin' ||
            logedInUser.roles[0].name === 'superadmin'
          "
        >
          <span v-if="row.item.shift_type.shift.toLowerCase() == 'afternoon'">{{
            makeFloat(row.item.shift_type.deduct_hours)
          }}</span>
          <span
            v-else-if="
              row.item.shift_type.next.next_shift.toLowerCase() == 'afternoon'
            "
            >{{ makeFloat(row.item.shift_type.deduct_next) }}</span
          >
          <span v-else>0.00</span>
        </div>
        <div v-else>
          <span v-if="row.item.shift_type.shift.toLowerCase() == 'afternoon'">{{
            makeFloat(row.item.shift_type.hours)
          }}</span>
          <span
            v-else-if="
              row.item.shift_type.next.next_shift.toLowerCase() == 'afternoon'
            "
            >{{ makeFloat(row.item.shift_type.next.hours) }}</span
          >
          <span v-else>0.00</span>
        </div>
      </template>
      <template #cell(n)="row">
        <div
          v-if="
            logedInUser.roles[0].name === 'admin' ||
            logedInUser.roles[0].name === 'superadmin'
          "
        >
          <span v-if="row.item.shift_type.shift.toLowerCase() == 'night'">{{
            makeFloat(row.item.shift_type.deduct_hours)
          }}</span>
          <span
            v-else-if="
              row.item.shift_type.next.next_shift.toLowerCase() == 'night'
            "
            >{{ makeFloat(row.item.shift_type.deduct_next) }}</span
          >
          <span v-else>0.00</span>
        </div>
        <div v-else>
          <span v-if="row.item.shift_type.shift.toLowerCase() == 'night'">{{
            makeFloat(row.item.shift_type.hours)
          }}</span>
          <span
            v-else-if="
              row.item.shift_type.next.next_shift.toLowerCase() == 'night'
            "
            >{{ makeFloat(row.item.shift_type.next.hours) }}</span
          >
          <span v-else>0.00</span>
        </div>
      </template>
      <template #cell(ot_1)="row">
        <span
          v-if="
            logedInUser.roles[0].name === 'admin' ||
            logedInUser.roles[0].name === 'superadmin'
          "
          >{{ row.item.hours.adminOtOne }}
        </span>
        <span v-else>{{ row.item.hours.otOne }} </span>
      </template>
      <template #cell(ot_2)="row">
        <span
          v-if="
            logedInUser.roles[0].name === 'admin' ||
            logedInUser.roles[0].name === 'superadmin'
          "
          >{{ row.item.hours.adminOtTwo }}
        </span>
        <span v-else>{{ row.item.hours.otTwo }} </span>
      </template>
      <template #cell(total_hours)="row">
        <span
          v-if="
            logedInUser.roles[0].name === 'admin' ||
            logedInUser.roles[0].name === 'superadmin'
          "
          >{{
            getSum(
              row.item.hours.adminOrdinary,
              row.item.hours.adminOtOne,
              row.item.hours.adminOtTwo
            )
          }}
        </span>
        <span v-else
          >{{
            getSum(
              row.item.hours.ordinary,
              row.item.hours.otOne,
              row.item.hours.otTwo
            )
          }}
        </span>
      </template>
      <template #cell(status)="row">
        <div class="d-flex">
          <b-button
            v-if="row.item.status === 2"
            v-b-tooltip.hover.v-danger
            :title="row.item.note"
            variant="danger"
            size="sm"
            class="btn add-btn d-block status-btn"
          >
            <span class="d-flex justify-content-center">
              <span style="margin-right: 5px">Declined </span>
              <alert-circle-icon
                size="1x"
                class="custom-class"
              ></alert-circle-icon>
            </span>
          </b-button>
          <b-button
            v-if="row.item.status === 1 && row.item.verify === 1"
            variant="success"
            size="sm"
            class="btn add-btn d-block status-btn"
          >
            <span class="d-flex justify-content-center">
              <span>Approved</span>
            </span>
          </b-button>
          <b-button
            v-if="row.item.status === 1 && row.item.verify === 0"
            variant="success"
            size="sm"
            class="btn add-btn d-block status-btn"
          >
            <span class="d-flex justify-content-center">
              <span>Approved</span>
            </span>
          </b-button>
          <b-button
            v-if="row.item.status === 0"
            variant="warning"
            size="sm"
            class="btn add-btn d-block status-btn"
          >
            <span class="d-flex justify-content-center">
              <span>Pending</span>
            </span>
          </b-button>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import { AlertCircleIcon } from "vue-feather-icons";
import { calculateTotalHours } from "@/utils/calculation";
import { BButton, BTable, VBTooltip } from "bootstrap-vue";
import { getLogedInUser } from "@/utils/auth";
import moment from "moment";
export default {
  props: {
    rosterWorks: {
      type: Array,
      default: () => [],
    },
    fields: {
      type: Array,
      default: () => [],
    },
    form: {
      type: Number,
      default: 0,
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  components: {
    BButton,
    BTable,
    AlertCircleIcon,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      companies: [],
      logedInUser: JSON.parse(getLogedInUser()),
    };
  },
  mounted() {
    this.getCompanies();
  },
  methods: {
    makeFloat(number) {
      return parseFloat(number).toFixed(2);
    },
    getSum(ordinary, otOne, otTwo) {
      return calculateTotalHours(ordinary, otOne, otTwo);
    },
    formateTime(time) {
      // format time to 12 hours with AM/PM
      let hour = moment(time).format("hh:mm A");
      if (hour == "Invalid date") {
        return "N/A";
      }
      return hour;
    },
    formateDate(date) {
      // format date to dd-mm-YYYY
      return moment(date).format("DD-MM-YYYY");
    },
    getCompanies() {
      this.$store.dispatch("company/getCompanies").then((response) => {
        this.companies = response.data;
      });
    },
  },
};
</script>

<style scoped>
.status-btn {
  width: 95px !important;
  text-align: center;
}
</style>
