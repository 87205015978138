var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-table',{attrs:{"responsive":"","striped":"","hover":"","items":_vm.rosterWorks,"fields":_vm.fields,"empty-filtered-text":"No Timesheet Found"},scopedSlots:_vm._u([{key:"cell(SL)",fn:function(row){return [_c('span',[_vm._v(" "+_vm._s(_vm.form + row.index))])]}},{key:"cell(date)",fn:function(row){return [_c('span',[_vm._v(" "+_vm._s(_vm.formateDate(row.item.date)))])]}},{key:"cell(employee)",fn:function(row){return [_c('span',[_vm._v(_vm._s(row.item.employee ? row.item.employee.name : "N/A"))])]}},(_vm.logedInUser.roles[0].name !== 'employee')?{key:"cell(company)",fn:function(row){return [_c('span',[_vm._v(_vm._s(row.item.roster.company.name))])]}}:{key:"cell(assignedBy)",fn:function(row){return [_c('span',[_vm._v(_vm._s(row.item.roster.company.name))])]}},(_vm.logedInUser.roles[0].name !== 'employee')?{key:"cell(client)",fn:function(row){return [_c('span',[_vm._v(_vm._s(row.item.roster && row.item.roster.customer ? row.item.roster.customer.name : "N/A"))])]}}:{key:"cell(assignedTo)",fn:function(row){return [_c('span',[_vm._v(_vm._s(row.item.roster && row.item.roster.customer ? row.item.roster.customer.name : "N/A"))])]}},{key:"cell(in)",fn:function(row){return [_c('span',[_vm._v(_vm._s(_vm.formateTime(row.item.in_time)))])]}},{key:"cell(out)",fn:function(row){return [_c('span',[_vm._v(_vm._s(row.item.out_time === row.item.in_time ? '---' : _vm.formateTime(row.item.out_time)))])]}},{key:"cell(shift_hours)",fn:function(row){return [(
          _vm.logedInUser.roles[0].name === 'admin' ||
          _vm.logedInUser.roles[0].name === 'superadmin'
        )?_c('span',[_vm._v(_vm._s(row.item.hours.adminOrdinary)+" ")]):_c('span',[_vm._v(_vm._s(row.item.hours.ordinary)+" ")])]}},{key:"cell(m)",fn:function(row){return [(
          _vm.logedInUser.roles[0].name === 'admin' ||
          _vm.logedInUser.roles[0].name === 'superadmin'
        )?_c('div',[(row.item.shift_type.shift.toLowerCase() == 'morning')?_c('span',[_vm._v(_vm._s(_vm.makeFloat(row.item.shift_type.deduct_hours)))]):(
            row.item.shift_type.next.next_shift.toLowerCase() == 'morning'
          )?_c('span',[_vm._v(_vm._s(_vm.makeFloat(row.item.shift_type.deduct_next)))]):_c('span',[_vm._v("0.00")])]):_c('div',[(row.item.shift_type.shift.toLowerCase() == 'morning')?_c('span',[_vm._v(_vm._s(_vm.makeFloat(row.item.shift_type.hours)))]):(
            row.item.shift_type.next.next_shift.toLowerCase() == 'morning'
          )?_c('span',[_vm._v(_vm._s(_vm.makeFloat(row.item.shift_type.next.hours)))]):_c('span',[_vm._v("0.00")])])]}},{key:"cell(e)",fn:function(row){return [(
          _vm.logedInUser.roles[0].name === 'admin' ||
          _vm.logedInUser.roles[0].name === 'superadmin'
        )?_c('div',[(row.item.shift_type.shift.toLowerCase() == 'afternoon')?_c('span',[_vm._v(_vm._s(_vm.makeFloat(row.item.shift_type.deduct_hours)))]):(
            row.item.shift_type.next.next_shift.toLowerCase() == 'afternoon'
          )?_c('span',[_vm._v(_vm._s(_vm.makeFloat(row.item.shift_type.deduct_next)))]):_c('span',[_vm._v("0.00")])]):_c('div',[(row.item.shift_type.shift.toLowerCase() == 'afternoon')?_c('span',[_vm._v(_vm._s(_vm.makeFloat(row.item.shift_type.hours)))]):(
            row.item.shift_type.next.next_shift.toLowerCase() == 'afternoon'
          )?_c('span',[_vm._v(_vm._s(_vm.makeFloat(row.item.shift_type.next.hours)))]):_c('span',[_vm._v("0.00")])])]}},{key:"cell(n)",fn:function(row){return [(
          _vm.logedInUser.roles[0].name === 'admin' ||
          _vm.logedInUser.roles[0].name === 'superadmin'
        )?_c('div',[(row.item.shift_type.shift.toLowerCase() == 'night')?_c('span',[_vm._v(_vm._s(_vm.makeFloat(row.item.shift_type.deduct_hours)))]):(
            row.item.shift_type.next.next_shift.toLowerCase() == 'night'
          )?_c('span',[_vm._v(_vm._s(_vm.makeFloat(row.item.shift_type.deduct_next)))]):_c('span',[_vm._v("0.00")])]):_c('div',[(row.item.shift_type.shift.toLowerCase() == 'night')?_c('span',[_vm._v(_vm._s(_vm.makeFloat(row.item.shift_type.hours)))]):(
            row.item.shift_type.next.next_shift.toLowerCase() == 'night'
          )?_c('span',[_vm._v(_vm._s(_vm.makeFloat(row.item.shift_type.next.hours)))]):_c('span',[_vm._v("0.00")])])]}},{key:"cell(ot_1)",fn:function(row){return [(
          _vm.logedInUser.roles[0].name === 'admin' ||
          _vm.logedInUser.roles[0].name === 'superadmin'
        )?_c('span',[_vm._v(_vm._s(row.item.hours.adminOtOne)+" ")]):_c('span',[_vm._v(_vm._s(row.item.hours.otOne)+" ")])]}},{key:"cell(ot_2)",fn:function(row){return [(
          _vm.logedInUser.roles[0].name === 'admin' ||
          _vm.logedInUser.roles[0].name === 'superadmin'
        )?_c('span',[_vm._v(_vm._s(row.item.hours.adminOtTwo)+" ")]):_c('span',[_vm._v(_vm._s(row.item.hours.otTwo)+" ")])]}},{key:"cell(total_hours)",fn:function(row){return [(
          _vm.logedInUser.roles[0].name === 'admin' ||
          _vm.logedInUser.roles[0].name === 'superadmin'
        )?_c('span',[_vm._v(_vm._s(_vm.getSum( row.item.hours.adminOrdinary, row.item.hours.adminOtOne, row.item.hours.adminOtTwo ))+" ")]):_c('span',[_vm._v(_vm._s(_vm.getSum( row.item.hours.ordinary, row.item.hours.otOne, row.item.hours.otTwo ))+" ")])]}},{key:"cell(status)",fn:function(row){return [_c('div',{staticClass:"d-flex"},[(row.item.status === 2)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-danger",modifiers:{"hover":true,"v-danger":true}}],staticClass:"btn add-btn d-block status-btn",attrs:{"title":row.item.note,"variant":"danger","size":"sm"}},[_c('span',{staticClass:"d-flex justify-content-center"},[_c('span',{staticStyle:{"margin-right":"5px"}},[_vm._v("Declined ")]),_c('alert-circle-icon',{staticClass:"custom-class",attrs:{"size":"1x"}})],1)]):_vm._e(),(row.item.status === 1 && row.item.verify === 1)?_c('b-button',{staticClass:"btn add-btn d-block status-btn",attrs:{"variant":"success","size":"sm"}},[_c('span',{staticClass:"d-flex justify-content-center"},[_c('span',[_vm._v("Approved")])])]):_vm._e(),(row.item.status === 1 && row.item.verify === 0)?_c('b-button',{staticClass:"btn add-btn d-block status-btn",attrs:{"variant":"success","size":"sm"}},[_c('span',{staticClass:"d-flex justify-content-center"},[_c('span',[_vm._v("Approved")])])]):_vm._e(),(row.item.status === 0)?_c('b-button',{staticClass:"btn add-btn d-block status-btn",attrs:{"variant":"warning","size":"sm"}},[_c('span',{staticClass:"d-flex justify-content-center"},[_c('span',[_vm._v("Pending")])])]):_vm._e()],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }