<template>
  <div>
    <b-row>
      <b-col cols="12" lg="2" class="text-center">
        <div class="mt-1 ml-lg-2">
          <div class="file myDIV">
            <label for="input-file">
              <div v-if="!image && !customer.avatar" class="file myDIV w-100">
                <label for="input-file">
                  <img
                    src="@/assets/images/icons/upload-image.png"
                    alt="upload-img"
                    class="img-fluid"
                  />
                </label>
                <input
                  @change="getImage($event)"
                  id="input-file"
                  type="file"
                  :disabled="mode == 'show'"
                />
              </div>
              <div v-else class="img-border rounded-circle">
                <img
                  :src="
                    image
                      ? image
                      : customer.avatar &&
                        customer.avatar.includes('https://ui-avatars.com')
                      ? customer.avatar
                      : $store.state.appConfig.imageBaseUrl + customer.avatar
                  "
                  alt="upload-img"
                  class="img-fluid rounded-circle user-img"
                />
              </div>
            </label>
            <input
              :disabled="mode == 'show'"
              @change="getImage($event)"
              id="input-file"
              type="file"
            />
          </div>
        </div>
      </b-col>

      <b-col cols="12" md="10">
        <validation-observer ref="simpleRules">
          <b-form>
            <p class="head-text">Basic Information</p>
            <hr />
            <validation-observer ref="basicRules">
              <b-row class="pr-1">
                <b-col cols="12" md="6">
                  <b-form-group label-for="Name">
                    <label for="Name">
                      Name
                      <span :hidden="mode === 'show'" class="text-danger"
                        >*</span
                      ></label
                    >
                    <validation-provider
                      #default="{ errors }"
                      name="Name"
                      rules="required"
                    >
                      <b-form-input
                        id="Name"
                        v-model="customer.first_name"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Enter Client  Name"
                        class="data-text"
                        :readonly="mode === 'show'"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="6"
                  v-if="logedInUser.roles[0].name === 'superadmin'"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Affiliated Company"
                    rules="required"
                  >
                    <b-form-group :state="errors.length > 0 ? false : null">
                      <label class="" for="Affiliated Company Name">
                        Affiliated Company Name
                        <span :hidden="mode === 'show'" class="text-danger"
                          >*</span
                        ></label
                      >
                      <v-select
                        id="Affiliated Company Name"
                        v-model="SelectedCompany"
                        :options="companies"
                        label="name"
                        placeholder="Select Affiliated Company"
                        :disabled="mode === 'show'"
                      >
                        <div
                          class="d-flex justify-content-between"
                          slot="no-options"
                          v-if="$can('create', 'Company')"
                        >
                          <span> Company is not available. </span>
                          <b-button
                            size="sm"
                            class="btn btn-info border pr-1"
                            v-b-modal.modal-lg
                            variant="outline-primary"
                            >Add +</b-button
                          >
                        </div>
                      </v-select>
                      <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                      >
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>

              <b-row class="pr-1">
                <b-col cols="12" md="6">
                  <b-form-group id="" label-for="contactNumber">
                    <label for="contactNumber">
                      Business Contact Number
                      <span :hidden="mode === 'show'" class="text-danger"
                        >*</span
                      ></label
                    >
                    <validation-provider
                      #default="{ errors }"
                      rules="required|integer"
                      name="Business Contact Number"
                    >
                      <b-form-input
                        id="contactNumber"
                        v-model="customer.phone"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Enter Business Contact Number"
                        type="number"
                        class="data-text"
                        :readonly="mode === 'show'"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col cols="12" md="6">
                  <b-form-group label-for="Business Email">
                    <label for="Business Email">
                      Business Email
                      <span :hidden="mode === 'show'" class="text-danger"
                        >*</span
                      ></label
                    >
                    <validation-provider
                      #default="{ errors }"
                      name="Business Email"
                      rules="required|email"
                    >
                      <b-form-input
                        id="Business Email"
                        v-model="customer.email"
                        :state="errors.length > 0 ? false : null"
                        type="email"
                        placeholder="Business Email"
                        class="data-text"
                        :readonly="mode === 'show'"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="pr-1">
                <b-col cols="12" md="6">
                  <b-form-group id="" label="Industry" label-for="Industry">
                    <v-select
                      id="Industry"
                      v-model="SelectedIndustry"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="name"
                      :options="industries"
                      placeholder="Select Industry"
                      :disabled="mode === 'show'"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <b-form-group>
                    <label for="ABN">ABN</label>
                    <b-form-input
                      id="ABN"
                      v-model="customer.customer.abn"
                      placeholder="00000000000"
                      required
                      class="data-text"
                      :readonly="mode === 'show'"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="pr-1">
                <b-col cols="12" md="6">
                  <b-form-group>
                    <label for="ACN">ACN</label>

                    <b-form-input
                      id="ACN"
                      v-model="customer.customer.acn"
                      placeholder="00000000000000000"
                      required
                      class="data-text"
                      :readonly="mode === 'show'"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <b-form-group>
                    <label for="TTFN">TFN</label>
                    <b-form-input
                      id="TTFN"
                      v-model="customer.customer.tfn"
                      placeholder="000000000000000"
                      class="data-text"
                      :readonly="mode === 'show'"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="pr-1">
                <b-col cols="12" md="6">
                  <b-form-group
                    label="Business Address"
                    label-for="Business Address"
                  >
                    <b-form-input
                      id="Business Address"
                      v-model="customer.customer.address"
                      placeholder="Business Address"
                      required
                      class="data-text"
                      :readonly="mode === 'show'"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <b-form-group id="" label="State" label-for="State">
                    <b-form-input
                      id="State"
                      v-model="customer.customer.city"
                      placeholder="Enter State"
                      required
                      class="data-text"
                      :readonly="mode === 'show'"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <b-form-group
                    id=""
                    label="Businees Type"
                    label-for="Businees-Type"
                  >
                    <div>
                      <b-form-group v-slot="{ ariaDescribedby }">
                        <b-form-radio-group
                          v-model="customer.customer.is_roster"
                          :options="options"
                          :aria-describedby="ariaDescribedby"
                          name="radio-inline"
                          :disabled="mode === 'show'"
                          @input="getLocations(customer.customer.is_roster)"
                        ></b-form-radio-group>
                      </b-form-group>
                    </div>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <b-form-group
                    id="radio-gps"
                    label="GPS Based Attendance Tracking System Required"
                    label-for="Businees-Type"
                  >
                    <div>
                      <b-form-group v-slot="{ ariaDescribedby }">
                        <b-form-radio-group
                          v-model="customer.customer.gps"
                          :options="gpsOptions"
                          :aria-describedby="ariaDescribedby"
                          name="radio-inline-gps"
                          :disabled="mode === 'show'"
                        ></b-form-radio-group>
                      </b-form-group>
                    </div>
                  </b-form-group>
                </b-col>
                <b-col v-if="customer.customer.is_roster == 0 && mode === 'create'" cols="12" md="6">
                  <b-form-group>
                    <label for="Primary Location">
                      Primary Location
                      <span :hidden="mode === 'show'" class="text-danger"
                        >*</span
                      ></label
                    >
                    <validation-provider
                      #default="{ errors }"
                      name="Primary Location"
                      rules="required"
                    >
                    <v-select
                      id="Location"
                      v-model="SelectedLocation"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="name"
                      :options="locations"
                      placeholder="Select Location"
                      :disabled="mode === 'show'"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row
                v-if="mode === 'edit' && $can('update', 'Client')"
                class="pr-1"
              >
                <b-col v-if="$route.params.id" class="text-right">
                  <button @click.prevent="handleSubmit" class="btn update-btn">
                    Update
                  </button>
                </b-col>
              </b-row>
            </validation-observer>
            <validation-observer ref="concernRules">
              <b-row class="my-2 d-flex justify-content-lg-end">
                <b-col cols="12">
                  <p class="head-text">Primary Contacts</p>
                  <hr />
                  <div
                    v-for="(contact, index) in customer.customer.contacts"
                    :key="index"
                  >
                    <b-row>
                      <b-col cols="12" md="6">
                        <b-form-group id="" label-for="FirstName">
                          <label>
                            First Name
                            <span :hidden="mode === 'show'" class="text-danger"
                              >*</span
                            ></label
                          >
                          <validation-provider
                            #default="{ errors }"
                            name="First Name"
                            rules="required"
                          >
                            <b-form-input
                              v-model="contact.first_name"
                              :state="errors.length > 0 ? false : null"
                              placeholder="Enter First Name"
                              class="data-text"
                              :readonly="mode === 'show'"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" md="6">
                        <b-form-group id="" label-for="FirstName">
                          <label
                            >Last Name
                            <span :hidden="mode === 'show'" class="text-danger"
                              >*</span
                            ></label
                          >
                          <validation-provider
                            #default="{ errors }"
                            name="Last Name"
                            rules="required"
                          >
                            <b-form-input
                              v-model="contact.last_name"
                              :state="errors.length > 0 ? false : null"
                              placeholder="Enter Last Name"
                              class="data-text"
                              :readonly="mode === 'show'"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12" md="6">
                        <b-form-group id="" label-for="ContactNo">
                          <label>
                            Contact No.
                            <span :hidden="mode === 'show'" class="text-danger"
                              >*</span
                            ></label
                          >
                          <validation-provider
                            #default="{ errors }"
                            rules="required|integer"
                            name="Contact Number"
                          >
                            <b-form-input
                              v-model="contact.phone"
                              :state="errors.length > 0 ? false : null"
                              placeholder="Enter Contact Number"
                              type="number"
                              class="data-text"
                              :readonly="mode === 'show'"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" md="6">
                        <b-form-group id="" label-for="email">
                          <label>
                            Email
                            <span :hidden="mode === 'show'" class="text-danger"
                              >*</span
                            ></label
                          >
                          <validation-provider
                            #default="{ errors }"
                            name="Email"
                            rules="required|email"
                          >
                            <b-form-input
                              v-model="contact.email"
                              :state="errors.length > 0 ? false : null"
                              type="email"
                              placeholder="Enter Email"
                              class="data-text"
                              :readonly="mode === 'show'"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12" md="6">
                        <b-form-group
                          id=""
                          label="Designation"
                          label-for="Designation"
                        >
                          <b-form-input
                            id="designation_id"
                            v-model="contact.designation_id"
                            type="text"
                            placeholder="Enter Desgination"
                            required
                            class="data-text"
                            :readonly="mode === 'show'"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" md="6">
                        <b-form-group id="" label="." label-for="">
                          <div class="d-flex justify-content-end">
                            <button
                              v-if="
                                customer.customer.contacts.length > 1 &&
                                mode !== 'show'
                              "
                              @click.prevent="removeContact(index)"
                              class="btn btn-danger btn-sm mr-1"
                            >
                              X
                            </button>
                            <div
                              v-if="
                                index === customer.customer.contacts.length - 1
                              "
                            >
                              <button
                                v-if="
                                  mode !== 'show' && $can('update', 'Client')
                                "
                                @click.prevent="addNewConcern"
                                class="btn btn-success btn-sm mr-1"
                              >
                                +
                              </button>
                            </div>
                          </div>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <hr />
                  </div>

                  <!-- <b-row>
                  <b-col cols="12" class="d-flex justify-content-end">
                    <b-link
                      v-if="
                        mode === 'edit' ||
                        (mode === 'create' && $can('update', 'Client'))
                      "
                      to="/customers"
                      class="btn btn-danger mr-1"
                    >
                      Cancel
                    </b-link>
                    <button
                      v-if="mode === 'create' && $can('create', 'Client')"
                      @click.prevent="handleSubmit"
                      class="btn update-btn mr-1"
                    >
                      Save
                    </button>
                    <button
                      v-if="mode === 'edit' && $can('update', 'Client')"
                      @click.prevent="updateConcern"
                      class="btn update-btn mr-1"
                    >
                      Save
                    </button>
                  </b-col>
                </b-row> -->
                </b-col>
              </b-row>

              <b-row class="my-2 d-flex justify-content-lg-end">
                <b-col cols="12">
                  <p class="head-text">Financial Contacts</p>
                  <hr />
                  <div
                    v-for="(contact, index) in customer.customer.financial"
                    :key="index"
                  >
                    <b-row>
                      <b-col cols="12" md="6">
                        <b-form-group id="" label-for="FirstName">
                          <label>
                            First Name
                            <span :hidden="mode === 'show'" class="text-danger"
                              >*</span
                            ></label
                          >
                          <validation-provider
                            #default="{ errors }"
                            name="Financial Contact First Name"
                            rules="required"
                          >
                            <b-form-input
                              v-model="contact.first_name"
                              :state="errors.length > 0 ? false : null"
                              placeholder="Enter First Name"
                              class="data-text"
                              :readonly="mode === 'show'"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" md="6">
                        <b-form-group id="" label-for="FirstName">
                          <label
                            >Last Name
                            <span :hidden="mode === 'show'" class="text-danger"
                              >*</span
                            ></label
                          >
                          <validation-provider
                            #default="{ errors }"
                            name="Financial Contact Last Name"
                            rules="required"
                          >
                            <b-form-input
                              v-model="contact.last_name"
                              :state="errors.length > 0 ? false : null"
                              placeholder="Enter Last Name"
                              class="data-text"
                              :readonly="mode === 'show'"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12" md="6">
                        <b-form-group id="" label-for="ContactNo">
                          <label>
                            Contact No.
                            <span :hidden="mode === 'show'" class="text-danger"
                              >*</span
                            ></label
                          >
                          <validation-provider
                            #default="{ errors }"
                            rules="required|integer"
                            name="Financial Person Contact Number"
                          >
                            <b-form-input
                              v-model="contact.phone"
                              :state="errors.length > 0 ? false : null"
                              placeholder="Enter Contact Number"
                              type="number"
                              class="data-text"
                              :readonly="mode === 'show'"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" md="6">
                        <b-form-group id="" label-for="email">
                          <label>
                            Email
                            <span :hidden="mode === 'show'" class="text-danger"
                              >*</span
                            ></label
                          >
                          <validation-provider
                            #default="{ errors }"
                            name="Financial Person Email"
                            rules="required|email"
                          >
                            <b-form-input
                              v-model="contact.email"
                              :state="errors.length > 0 ? false : null"
                              type="email"
                              placeholder="Enter Email"
                              class="data-text"
                              :readonly="mode === 'show'"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12" md="6">
                        <b-form-group
                          id=""
                          label="Designation"
                          label-for="Designation"
                        >
                          <b-form-input
                            id="designation_id"
                            v-model="contact.designation_id"
                            type="text"
                            placeholder="Enter Desgination"
                            required
                            class="data-text"
                            :readonly="mode === 'show'"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" md="6">
                        <b-form-group id="" label="." label-for="">
                          <div class="d-flex justify-content-end">
                            <button
                              v-if="
                                customer.customer.financial.length > 1 &&
                                mode !== 'show'
                              "
                              @click.prevent="removeFinancialContact(index)"
                              class="btn btn-danger btn-sm mr-1"
                            >
                              X
                            </button>
                            <div
                              v-if="
                                index === customer.customer.financial.length - 1
                              "
                            >
                              <button
                                v-if="
                                  mode !== 'show' && $can('update', 'Client')
                                "
                                @click.prevent="addFinancialNewConcern"
                                class="btn btn-success btn-sm mr-1"
                              >
                                +
                              </button>
                            </div>
                          </div>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <hr />
                  </div>

                  <b-row>
                    <b-col cols="12" class="d-flex justify-content-end">
                      <b-link
                        v-if="
                          mode === 'edit' ||
                          (mode === 'create' && $can('update', 'Client'))
                        "
                        :to="`${
                          logedInUser.roles[0].name === 'customer'
                            ? '/'
                            : '/customers'
                        }`"
                        class="btn btn-danger mr-1"
                      >
                        Cancel
                      </b-link>
                      <button
                        v-if="mode === 'create' && $can('create', 'Client')"
                        @click.prevent="handleSubmit"
                        class="btn update-btn mr-1"
                      >
                        Save
                      </button>
                      <button
                        v-if="mode === 'edit' && $can('update', 'Client')"
                        @click.prevent="updateConcern"
                        class="btn update-btn mr-1"
                      >
                        Save
                      </button>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </validation-observer>
          </b-form>
        </validation-observer>
      </b-col>
    </b-row>

    <b-modal
      id="modal-lg"
      centered
      size="xl"
      title="Create Affiliated Company"
      hide-footer
      body-bg-variant="light"
      header-bg-variant="white"
      ref="modal-lg"
    >
      <createCompany
        @getCompanies="
          getCompanies();
          hideModal();
        "
        mode="create"
        :company="company"
        CreateFrom="customer"
      />
    </b-modal>
    <validation-observer ref="passwordForm">
      <b-form class="mx-1">
        <b-row v-if="mode === 'edit' && $can('change password', 'Client')">
          <b-col cols="2"></b-col>
          <b-col cols="10" class="py-2">
            <b-button
              v-b-modal.modal-1
              class="password-btn btn shadow w-100 d-flex justify-content-between align-items-center border-0"
            >
              Change Password
              <img src="@/assets/images/icons/lock.png" alt="" />
            </b-button>
          </b-col>

          <b-col cols="12">
            <b-modal id="modal-1" title="Change Password" hide-footer>
              <p class="text-center modal-title">Rules of Strong Password</p>
              <p class="text-center modal-sub-title">
                A - Z | a - z | 0 - 9 | Special Character | Minimum 8 Characters
              </p>
              <b-row>
                <b-col
                  v-if="logedInUser.roles[0].name === 'customer'"
                  cols="12"
                >
                  <b-form-group>
                    <label for="">
                      Existing Password
                      <span class="text-danger">*</span></label
                    >
                    <validation-provider
                      #default="{ errors }"
                      name="Old Password"
                      vid="OldPassword"
                      rules="required|min:8"
                    >
                      <b-form-input
                        id="a-password"
                        v-model="passwordForm.old_password"
                        :state="errors.length > 0 ? false : null"
                        type="password"
                        placeholder="Type your old password as condition"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group>
                    <label for="">
                      New Password <span class="text-danger">*</span></label
                    >
                    <validation-provider
                      #default="{ errors }"
                      name="New Password"
                      vid="Password"
                      rules="required|password|min:8"
                    >
                      <b-form-input
                        id="ac-password"
                        v-model="passwordForm.password"
                        :state="errors.length > 0 ? false : null"
                        type="password"
                        placeholder="Type your new password as condition"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <label for="">
                    Re-Type New Password
                    <span class="text-danger">*</span></label
                  >
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Re-Type New Password"
                      rules="required|confirmed:Password"
                    >
                      <b-form-input
                        id="ar-password"
                        v-model="passwordForm.password_confirmation"
                        :state="errors.length > 0 ? false : null"
                        type="password"
                        placeholder="Re-Type your new password as condition"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col class="text-center">
                  <button
                    @click="$bvModal.hide('modal-1')"
                    class="btn mx-1 cancel"
                  >
                    Cancel
                  </button>
                  <button @click.prevent="updatePassword" class="btn mx-1 save">
                    Save
                  </button>
                </b-col>
              </b-row>
            </b-modal>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { getLogedInUser } from "@/utils/auth";
import { toast } from "@/utils/toast";
import createCompany from "@/views/company/components/company-basic-information.vue";
import { email, required } from "@validations";
import {
  BButton,
  BCard,
  BCol,
  BForm,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormDatepicker,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormSelect,
  BFormTextarea,
  BLink,
  BModal,
  BRow,
  BFormRadio,
  BFormRadioGroup,
} from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import vSelect from "vue-select";
export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormCheckboxGroup,
    BFormDatepicker,
    BFormCheckbox,
    BButton,
    BCard,
    BFormTextarea,
    BFormFile,
    BButton,
    BModal,
    BLink,
    BFormInvalidFeedback,
    BFormRadio,
    BFormRadioGroup,
    ValidationProvider,
    ValidationObserver,
    createCompany,
    vSelect,
  },
  props: {
    customer: {
      type: Object,
      default: () => ({}),
    },
    mode: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      logedInUser: JSON.parse(getLogedInUser()),
      passwordForm: {
        id: this.$route.params.id,
        old_password: "",
        password: "",
        password_confirmation: "",
      },
      industries: [],
      companies: [],
      locations: [],
      SelectedCompany: null,
      SelectedDesignation: null,
      SelectedIndustry: null,
      SelectedLocation: null,
      image: null,
      loading: false,
      email,
      required,
      company: {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        name: "",
        info: {
          industry: "",
          abn: "",
          acn: "",
          tfn: "",
          city: "",
          state: "",
        },
      },
      options: [
        { text: "Preferred Mandatory Roster", value: "1" },
        { text: "Preferred No Roster", value: "0" },
      ],
      gpsOptions: [
        { text: "Yes", value: "1" },
        { text: "No", value: "0" },
      ],
    };
  },
  created() {
    this.getCompanies();
    this.getIndustries();
    if (this.customer.customer === undefined) {
      this.customer.customer = {
        name: "",
        abn: "",
        industry: {},
      };
    }
  },
  mounted() {
    setTimeout(() => {
      this.loading = true;

      if (
        this.$route.params.id &&
        this.customer.customer_to_company.length > 0
      ) {
        this.SelectedCompany = this.customer.customer_to_company[0];
      }
      if (this.$route.params.id && this.customer.customer.industry) {
        this.SelectedIndustry = this.customer.customer.industry;
      }
    }, 2000);

    // this.getLocations();
  },
  methods: {
    hideModal() {
      this.$refs["modal-lg"].hide();
    },
    getLocations(is_roster) {
      if (is_roster == 0) {
        this.$store.dispatch("location/getLocationsDropdown").then((response) => {
          this.locations = response.data;
        });
      }
    },
    updatePassword() {
      this.$refs.passwordForm.validate().then((success) => {
        if (success) {
          // eslint-disable-next-line
          this.$store
            .dispatch("user/updateUserPassword", this.passwordForm)
            .then((res) => {
              if (res.status === 200) {
                this.$bvModal.hide("modal-1");
                // toast("Successfully Updated");
                toast(
                  "Success",
                  "CheckCircleIcon",
                  "success",
                  "Successfully Updated"
                );
              }
            })
            .catch((error) => {
              let errorResponse = error.response?.data?.error;
              for (const key in errorResponse) {
                if (errorResponse.hasOwnProperty(key)) {
                  const errorMessages = errorResponse[key];
                  // Loop through each error message in the array
                  for (const errorMessage of errorMessages) {
                    toast("Error", "AlertTriangleIcon", "danger", errorMessage);
                  }
                }
              }
            });
        } else {
          toast(
            "Validation Error",
            "AlertTriangleIcon",
            "danger",
            "Please fill all the required fields"
          );
        }
      });
    },

    handleSubmit() {
      if (this.logedInUser.roles[0].name === "admin") {
        let roles = this.logedInUser.roles.map((role) => {
          return role.name;
        });
        if (roles.includes("supervisor")) {
          this.customer.company_id = this.logedInUser.supervisor.company_id;
        } else {
          this.customer.company_id = this.logedInUser.id;
        }
      }

      if (this.mode === "create") {
        this.$refs.simpleRules.validate().then((success) => {
          if (success) {
            // eslint-disable-next-line
            this.addCustomer();
          } else {
            toast(
              "Validation Error",
              "AlertTriangleIcon",
              "danger",
              "Please fill all the required fields"
            );
          }
        });
      }
      if (this.mode === "edit") {
        this.$refs.basicRules.validate().then((success) => {
          if (success) {
            // eslint-disable-next-line
            this.updateCustomer();
          } else {
            toast(
              "Validation Error",
              "AlertTriangleIcon",
              "danger",
              "Please fill all the required fields"
            );
          }
        });
      }
    },
    // get all the companies
    async getCompanies() {
      let res = await this.$store.dispatch("company/getCompanies");
      this.companies = res.data;
    },
    getImage(e) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        this.image = e.target.result;
      };
    },
    addNewConcern() {
      console.log("add new concern", this.customer.customer.contacts);
      this.customer.customer.contacts.push({
        first_name: "",
        last_name: "",
        designation_id: "",
        phone: "",
        email: "",
      });
    },
    removeContact(index) {
      if (this.customer.customer.contacts.length > 1) {
        this.customer.customer.contacts.splice(index, 1);
      }
    },
    addFinancialNewConcern() {
      this.customer.customer.financial.push({
        first_name: "",
        last_name: "",
        designation_id: "",
        phone: "",
        email: "",
        type: "financial",
      });
    },
    removeFinancialContact(index) {
      if (this.customer.customer.financial.length > 1) {
        this.customer.customer.financial.splice(index, 1);
      }
    },
    addCustomer() {
      if (this.SelectedIndustry) {
        this.customer.industry = this.SelectedIndustry.id;
      }

      if (this.SelectedCompany) {
        this.customer.company_id = this.SelectedCompany.id;
      }

      if (this.SelectedLocation) {
        this.customer.location_id = this.SelectedLocation.id;
      }

      this.customer.contacts = [
        ...this.customer.customer.contacts,
        ...this.customer.customer.financial,
      ];
      this.customer.abn = this.customer.customer.abn;
      this.customer.acn = this.customer.customer.acn;
      this.customer.tfn = this.customer.customer.tfn;
      this.customer.address = this.customer.customer.address;
      this.customer.city = this.customer.customer.city;
      this.customer.avatar = this.image;
      this.customer.is_roster = this.customer.customer.is_roster;
      this.customer.gps = this.customer.customer.gps;

      this.customer.contacts.forEach((element) => {
        element.designation = element.designation_id;
      });

      this.$store
        .dispatch("customer/createCustomer", this.customer)
        .then((response) => {
          if (response.status_code === 200) {
            toast(
              "Success",
              "CheckCircleIcon",
              "success",
              "Client Created Successfully"
            );
            if (this.logedInUser.roles[0].name === "customer") {
              this.$router.push("/");
            } else {
              this.$router.push("/customers");
            }
          }
        })
        .catch((error) => {
          let errorResponse = error.response?.data?.errors;
          for (const key in errorResponse) {
            if (errorResponse.hasOwnProperty(key)) {
              const errorMessages = errorResponse[key];
              // Loop through each error message in the array
              for (const errorMessage of errorMessages) {
                toast("Error", "AlertTriangleIcon", "danger", errorMessage);
              }
            }
          }
        });
    },

    updateCustomer() {
      if (this.SelectedCompany) {
        this.customer.company_id = this.SelectedCompany.id;
      }
      if (this.SelectedIndustry) {
        this.customer.industry = this.SelectedIndustry.id;
      }

      this.customer.contacts = [
        ...this.customer.customer.contacts,
        ...this.customer.customer.financial,
      ];
      this.customer.abn = this.customer.customer.abn;
      this.customer.acn = this.customer.customer.acn;
      this.customer.tfn = this.customer.customer.tfn;
      this.customer.address = this.customer.customer.address;
      this.customer.city = this.customer.customer.city;
      this.customer.avatar = this.image ? this.image : this.customer.avatar;
      this.customer.is_roster = this.customer.customer.is_roster;
      this.customer.gps = this.customer.customer.gps;
      this.$store
        .dispatch("customer/updateCustomer", this.customer)
        .then((response) => {
          if (response.status_code === 200) {
            toast(
              "Success",
              "CheckCircleIcon",
              "success",
              "Client Updated Successfully"
            );
            if (this.logedInUser.roles[0].name === "customer") {
              this.$router.push("/");
            } else {
              this.$router.push("/customers");
            }
          }
        })
        .catch((error) => {
          let errorResponse = error.response?.data?.errors;
          for (const key in errorResponse) {
            if (errorResponse.hasOwnProperty(key)) {
              const errorMessages = errorResponse[key];
              // Loop through each error message in the array
              for (const errorMessage of errorMessages) {
                toast("Error", "AlertTriangleIcon", "danger", errorMessage);
              }
            }
          }
        });
    },
    updateConcern() {
      let hasError = false;
      this.customer.customer.contacts.forEach((element) => {
        element.designation = element.designation_id;
      });
      this.customer.customer.financial.forEach((element) => {
        element.designation = element.designation_id;
      });
      this.$refs.concernRules.validate().then((success) => {
        if (success) {
          // eslint-disable-next-line
          this.$store
            .dispatch("customer/updateContact", {
              id: this.customer.primary_id,
              contacts: [
                ...this.customer.customer.contacts,
                ...this.customer.customer.financial,
              ],
            })
            .then((response) => {
              if (response.status_code === 200) {
                toast(
                  "Success",
                  "CheckCircleIcon",
                  "success",
                  "Client Updated Successfully"
                );
                this.$router.push({ name: "Client List" });
              }
            })
            .catch((error) => {
              let errorResponse = error.response?.data?.errors;
              for (const key in errorResponse) {
                if (errorResponse.hasOwnProperty(key)) {
                  const errorMessages = errorResponse[key];
                  // Loop through each error message in the array
                  for (const errorMessage of errorMessages) {
                    toast("Error", "AlertTriangleIcon", "danger", errorMessage);
                  }
                }
              }
            });
        } else {
          toast(
            "Validation Error",
            "AlertTriangleIcon",
            "danger",
            "Please fill all the required fields"
          );
        }
      });
    },

    getIndustries() {
      this.$store
        .dispatch("industry/getIndustries", this.query)
        .then((response) => {
          this.industries = response.data.data;
        });
    },
  },
};
</script>

<style scoped>
.update-btn {
  background: linear-gradient(315deg, #01185e 0%, #264296 44.92%, #7190ef 100%);
  border-radius: 5px;
  color: white;
}

.data-text {
  /* 18 bold */
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height */
  /* blue main */
  color: #01185e;
}

.file {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.file > input[type="file"] {
  display: none;
}

.file > label {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

@keyframes pulse {
  0% {
    color: hsl(48, 100%, 67%);
  }

  50% {
    color: hsl(48, 100%, 38%);
  }

  100% {
    color: hsl(48, 100%, 67%);
  }
}

.hide {
  display: none;
  position: absolute;
  margin-top: -100px;
}

.myDIV:hover + .hide {
  display: block;
  color: red;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

/* input field text color */
.data-text {
  /* 18 bold */
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height */
  /* blue main */
  color: #01185e !important;
}

.head-text {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  color: #515b60;
}

.cancel {
  background: rgba(255, 79, 93, 0.1);
  color: #ff4f5d;
}

.save {
  background: #0bbe32;
  color: white !important;
}

.password-btn {
  background: linear-gradient(#01185e 0%, #264296 44.92%, #7190ef 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
}

.modal-title {
  font-weight: 700;
  font-size: 18px;
  line-height: 43px;
  text-align: center;
  color: #515b60;
}

.modal-sub-title {
  font-weight: 600;
  font-size: 14px;
  line-height: 27px;
  text-align: center;
  color: red;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
